<template>
  <div class="box" style="width: 100%; height: 100%; padding: 10px;"> 
    <iframe 
      class="full" 
      src="map.html" 
      frameborder="0" 
      style="width: 100%; height: 100%;" 
      ref="unity">
    </iframe>
  </div>
</template>

<script>
  export default {
    name: 'Map',
    data() {
      return {
        zoom: 6,
        center: [118.671389, 43.521016],
        map: null
      }
    },
    created () {
      this.$nextTick(() => {
        window.addEventListener('message', (messageEvent) => {
          let val = messageEvent.data
          console.log('接收 iframe 发送的参数 : ', val)

          this.$emit('showUser', true)

          if (val.userInfo.name) {
            this.$emit('emitData', val)
          }
          
          // this.$router.push({
          //   path: "/bigscreen/dianBiaoJi"
          // })
        })
      })
    },
    mounted() {
      // this.login()
    },
    methods: {
      login() {
      var self = this;
      const subscribes = [];

      // // 订阅群发消息主题
      const subscribeAll = {};
      subscribeAll.topic = "/topic/position"

      subscribeAll.callback = ((val) => {
        if (val.body) {
          this.$refs.unity.contentWindow.getMessageFromParent(val.body);
        }
        

        // // this.charts += "\n 群发消息：" + val.body
        // // console.log("收到消息" + val);
        // console.log("body=" + val.body);
        // var body = JSON.parse(val.body)
        // var macId = body.mac;
        // this.messageMacId = body.mac;
        // // this.$message('收到消息: macid = '+ macId);
        // // el.classList.remove("animate__heartBeat");
        // // el.classList.add("animate__heartBeat");
        // var msgButton = document.getElementById("msgButton");

        // msgButton.addEventListener('animationend', () => {
        //   var msgButton = document.getElementById("msgButton");
        //   msgButton.classList.remove('animate__animated', 'animate__rubberBand');
        // });
        // // msgBox.innerHTML = `<span class="animate__animated animate__heartBeat">收到消息: ${macId}</span>`;
        // // console.log("");
        // console.log("id=" + macId);

        // var el_card = document.getElementById("id_beat_el_card_" + macId);
        // // var el_icon = document.getElementById("id_beat_el_icon_" + id);
        // // self.setBeat(el_card);
        // self.setBeat(el_card);
        // var el_ts = document.getElementById("id_ts_" + macId);
        // var ts_date = new Date(body.ts);
        // var time = `${ts_date.getHours()}:${ts_date.getMinutes()}:${ts_date.getSeconds()}`;
        // if(el_ts != null){
        //   el_ts.innerText = body.ts;
        // }

        // var elTimeRow = document.getElementById("timeRow_" + macId);
        // if(elTimeRow !== null){
        //   elTimeRow.classList.remove("heartFadeout");
        //   // self.removeClass(elTimeRow, "heartFadeout");
        //   window.requestAnimationFrame(function(time) {
        //     window.requestAnimationFrame(function(time) {
        //       // document.querySelector(".box").className = "box changing";/
        //       elTimeRow.classList.add("heartFadeout");
        //       msgButton.classList.add('animate__animated', 'animate__rubberBand');
        //       // self.addClass(elTimeRow, "heartFadeout");
        //     });
        //   });
        // }
        
      })
      subscribes.push(subscribeAll)

      // 点对点发消息主题
      // const subscribeUser = {};
      // subscribeUser.topic = "/queue/" + this.fromUserId + "/topic"
      // subscribeUser.callback = ((val) => {
      //   this.charts += "\n 点对点消息：" + val.body
      // })
      // subscribes.push(subscribeUser)

      // 将参数封闭起来一起带过去
      const params = {}
      // if (process.env.NODE_ENV === "development") {
      //   params.url = "http://hxyc.dayaar./websocket"
      // }else {
      //   params.url = "/pro/websocket"
      // }
      // params.url = "http://dsj.dayaar.com.cn/pro/websocket"
      params.url = "//dsj.dayaar.com.cn/pro/websocket"
      
      params.listenerList = subscribes

      // 初始化好链接，并订阅主题
      this.$ws.dispatch('WEBSOCKET_INIT', params)
      }
    }
  }
</script>

<style lang="less" scoped>
  .box {
    width: 100%;
    height: 100%;
  }
</style>