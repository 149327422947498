<template>
  <div class="box_1">
    <div class="header">
      <div class="text" @click="goToMenuPage()">巴林右旗农业统计</div> 
    </div>

    <div class="content_box">
      <!-- 1 -->
      <div class="content_item_box">
        <div class="left_box">
           <!-- <div class="title"><span>土地面积</span></div> -->
          <div class="title flex_start">
            <div class="w_110 main_color p_10 border_box fs_18 fw_bold">
              <span>土地面积</span>
            </div>

            <div class="w_100 h_40 mon_text lh_25 color_fff fs_16 main_color mt_10">
              (ᠭᠠᠵᠠᠷ ᠲᠠᠷᠢᠶᠠᠨ᠎ᠤ ᠲᠠᠯᠠᠪᠠᠢ)
            </div>
          </div>

          <bing-tu :value="data1" />
        </div>
 
        <div class="left_box">
          <!-- <div class="title"><span>农作物统计</span></div> -->
          <div class="title flex_start">
            <div class="w_110 main_color p_10 border_box fs_18 fw_bold">
              <span>农作物统计</span>
            </div>

            <div class="w_100 h_40 mon_text lh_25 color_fff fs_16 main_color mt_10">
              (ᠲᠠᠷᠢᠮᠠᠯ᠎ᠤᠨ ᠲᠣᠭ᠎ᠠ ᠪᠦᠷᠢᠳᠭᠡᠯ)
            </div>
          </div>

          <!-- <bing-tu :value="data2" /> -->
          <kong-xin-bing-tu :value="data2" />
        </div>
      </div> 

      <!-- 2 -->
      <div class="content_item_box">
        <div class="map_box">
          <!-- <ba-lin-you-qi-map class="map" /> -->
          <map-com class="map" />
        </div>

        <div class="context_box">
          巴林右旗位于内蒙古自治区东部，赤峰市北部，驻地大板镇，
          地处西拉沐沦河北岸，全旗总面积10256平方公里，
          下辖4个苏木、5个镇，截至2023年年初全旗户籍总人口18.64万人。人口密度为18人／平方公里。
        </div>

        <div class="bottom_box">
           <!-- <div class="title"><span>机械化</span></div> -->
          <div class="title flex_start">
            <div class="w_110 main_color p_10 border_box fs_18 fw_bold">
              <span>机械化</span>
            </div>

            <div class="w_100 h_40 mon_text lh_25 color_fff fs_16 main_color mt_10 ml_220">
              (ᠮᠠᠱᠢᠨᠴᠢᠯᠠᠡᠯ)
            </div>
          </div>

          <ban-bing-tu :value="data3" />
        </div>
      </div> 

      <!-- 3 -->
      <div class="content_item_box">
        <div class="su_mu_list">
          <!-- <div class="title"><span>管辖苏木、镇</span></div> -->

          <div class="title flex_start">
            <div class="w_110 main_color p_10 border_box fs_18 fw_bold">
              <span>管辖苏木、镇</span>
            </div>

            <div class="w_100 h_40 mon_text lh_25 color_fff fs_16 main_color mt_10 ml_250">
              (ᠬᠠᠷᠢᠶ᠎ᠠ ᠰᠤᠮᠤ᠂ ᠪᠠᠯᠭᠠᠰᠤ)
            </div>
          </div>

          <div class="list_header">
            <div class="num color_green">序号</div>
            <div class="village_name color_green">苏木（镇）名称</div>
          </div>

          <div class="list_box">
            <div class="list_item" v-for="(item, index) in list" :key="index">
              <span v-if="index < 9">0{{ index+1 }}</span> 
              <span v-else>{{ index+1 }}</span> 
                    
              <div class="name" @click="goToVillagePage(item.name)">{{ item.name }}</div> 
              <!-- <div class="value">{{ item.value }}</div>  -->
            </div>
          </div>

        </div>
      </div> 
    </div>
  </div>
</template>

<script>
  import BaLinYouQiMap from '../maps/baLinYouQiMap.vue'
  import ChinaMap from '../maps/chinaMap.vue'
  import BingTu from '../charts/bing-tu.vue'
  import DaBanMap from '../maps/daBanMap.vue'
  import BanBingTu from '../charts/ban-bing-tu.vue'
  import KongXinBingTu from '../charts/kong-xin-bing-tu.vue'
  import MapCom from '../../../../components/map/map.vue';

  export default {
    name: 'showPage1',
    components: { BaLinYouQiMap, BingTu, DaBanMap, ChinaMap, BanBingTu, KongXinBingTu, MapCom },
    data() {
      return {
        activeIndex: 0,
        mapName: '巴林右旗大板镇',

        data1: {},
        data2: {},
        data3: {},        

        list: [
          {name: "索博日嘎镇", value: 23},
          {name: "幸福之路苏木", value: 524},
          {name: "巴彦琥硕镇", value: 14},
          {name: "查干沐沦苏木", value: 150},
          {name: "大板镇", value: 75},
          {name: "大板煤电化基地", value: 13},
          {name: "巴彦塔拉苏木", value: 83},
          {name: "查干诺尔镇", value: 11},
          {name: "宝日勿苏镇", value: 19},
          {name: "西拉沐沦苏木", value: 15}   
        ],
        
      }
    },
    mounted() {
      setTimeout(() => {
        this.initChart()
      }, 100)

      this.$bus.$on('setData', val => {
        if (val.mapName !== '大板镇') {
          // this.data1 = val.zhong_zhi_zhong_lei
          // this.data2 = val.yang_zhi_lei
          // this.data3 = val.chan_liang

          for (let i in this.data2.data) {
            if (this.data2.data[i].value > 4000) {
              this.data2.data[i].value -= 83
            } else {
              this.data2.data[i].value += 98
            }
          }
        } else {
          this.initChart()
        }
      })
    },
    methods: {
      // 初始化图表
      initChart() {
        this.data1 = {
          title: '农用地面积',
          unit: '万亩',
          data: [
            {"name": "草木场", "value": 1286, "unit": ''},
            {"name": "耕地", "value": 80.85, "unit": ''},
            {"name": "水浇地", "value": 56, "unit": ''},
            {"name": "林地", "value": 393.6, "unit": ''}
          ]
        }

        this.data2 = {
          title: '农作物统计',
          unit: '万亩',
          data: [
            {"name": "粮食", "value": 112, "unit": ''},
            {"name": "经济及特色", "value": 40, "unit": ''},
            {"name": "青贮", "value": 40, "unit": ''}
          ]
        }

        this.data3 = {
          title: '机械化统计',
          unit: '辆',
          data: [
            {"name": "农用拖拉机", "value": 9987, "unit": ''},
            {"name": "配套农机", "value": 17000, "unit": ''},
            {"name": "打草机", "value": 8521, "unit": ''},
            {"name": "耕地机", "value": 6576, "unit": ''},
            {"name": "收割机", "value": 9243, "unit": ''}
          ]
        }
      },

      // 前往 巴林右旗 - 乡镇- 农业统计页面
      goToVillagePage(val) {
        this.$router.push({
          path: "/bigscreen/villageFarmal" + val
        })
      },
      
      // 跳转到 人口统计 图表详情
      goToPersonStateDetail() {
        let title = `${this.mapName}人口统计`

        this.$router.push({
          path: "/bigscreen/personState" + title
        })
      },

      // 跳转到 机械化统计 图表详情
      goToMashineStateDetail() {
        let title = `${this.mapName}机械化统计`

        this.$router.push({
          path: "/bigscreen/mashineState" + title
        })
      },

      // 跳转到 menu 页面
      goToMenuPage() {
        this.$router.push({
          path: "/bigscreen/menuPage"
        })
      }
    }
  }
</script>

<style scoped lang="less">
  @import url('../../../../assets/css/bigscreen.less');
  .activeColor { color: #2366fc !important; }

  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    
  }
</style>